/**
* #313E61 - #171e2e - Azul 1
* #395FC2 - #284287 - Azul 2
* #56CCF2 - #12b5ea - Azul 3
*/

/****************************************/
/** Fonts */
/****************************************/

@font-face {
  font-family: "Lato";
  src: url("./assets/fonts/Lato/Lato-Regular.ttf");
}

@font-face {
  font-family: "Lato";
  src: url("./assets/fonts/Lato/Lato-Bold.ttf");
  font-weight: bold;
}

/****************************************/
/** General */
/****************************************/

body {
  font-family: 'Lato';
  /* background-image: url('./assets/images/pattern.png'); */
}

.mirai-shadow {
  -webkit-box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.1);
}

/****************************************/
/** Botones */
/****************************************/

.btn-sl-1 {
  color: #fff;
  background-color: #313E61;
  border-color: #313E61;
}

.btn-sl-1.active, .btn-sl-1:hover, .btn-sl-1:focus, .btn-sl-1:active {
  color: #fff;
  background-color: #171e2e;
  border-color: #171e2e;
}

.btn-sl-2 {
  color: #fff;
  background-color: #395FC2;
  border-color: #395FC2;
}

.btn-sl-2.active, .btn-sl-2:hover, .btn-sl-2:focus, .btn-sl-2:active {
  color: #fff;
  background-color: #284287;
  border-color: #284287;
}

.btn-sl-3 {
  color: #fff;
  background-color: #56CCF2;
  border-color: #56CCF2;
}

.btn-sl-3.active, .btn-sl-3:hover, .btn-sl-3:focus, .btn-sl-3:active {
  color: #fff;
  background-color: #12b5ea;
  border-color: #12b5ea;
}

/** ********************** */
/** Forms */
/** ********************** */

form .form-group label {
  font-weight: bold;
  margin-bottom: .25rem;
  color: #333;
}

form .errors {
  margin-bottom: 0;
  padding-left: 15px;
  font-size: 0.85em;
  font-weight: bold;
  color: #dc3545;
}

form .btn-submit {
  color: #fff;
  background-color: #395FC2;
  border-color: #395FC2;
  /** pa la card */
  margin-top: .5rem;
  float: right;
  width: 25%;
}

form .btn-submit.active, form .btn-submit:hover, form .btn-submit:focus, form .btn-submit:active {
  color: #fff;
  background-color: #284287;
  border-color: #284287;
}

form .wafoformautocomplete>.results {
  top: 68px;
  -webkit-box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.1);
}