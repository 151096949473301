/**
* #313E61 - #171e2e - Azul 1
* #395FC2 - #284287 - Azul 2
* #56CCF2 - #12b5ea - Azul 3
*/

/****************************************/
/** Fonts */
/****************************************/

@font-face {
  font-family: "Lato";
  src: url(/static/media/Lato-Regular.7f690e50.ttf);
}

@font-face {
  font-family: "Lato";
  src: url(/static/media/Lato-Bold.44dfe8cc.ttf);
  font-weight: bold;
}

/****************************************/
/** General */
/****************************************/

body {
  font-family: 'Lato';
  /* background-image: url('./assets/images/pattern.png'); */
}

.mirai-shadow {
  box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.1);
}

/****************************************/
/** Botones */
/****************************************/

.btn-sl-1 {
  color: #fff;
  background-color: #313E61;
  border-color: #313E61;
}

.btn-sl-1.active, .btn-sl-1:hover, .btn-sl-1:focus, .btn-sl-1:active {
  color: #fff;
  background-color: #171e2e;
  border-color: #171e2e;
}

.btn-sl-2 {
  color: #fff;
  background-color: #395FC2;
  border-color: #395FC2;
}

.btn-sl-2.active, .btn-sl-2:hover, .btn-sl-2:focus, .btn-sl-2:active {
  color: #fff;
  background-color: #284287;
  border-color: #284287;
}

.btn-sl-3 {
  color: #fff;
  background-color: #56CCF2;
  border-color: #56CCF2;
}

.btn-sl-3.active, .btn-sl-3:hover, .btn-sl-3:focus, .btn-sl-3:active {
  color: #fff;
  background-color: #12b5ea;
  border-color: #12b5ea;
}

/** ********************** */
/** Forms */
/** ********************** */

form .form-group label {
  font-weight: bold;
  margin-bottom: .25rem;
  color: #333;
}

form .errors {
  margin-bottom: 0;
  padding-left: 15px;
  font-size: 0.85em;
  font-weight: bold;
  color: #dc3545;
}

form .btn-submit {
  color: #fff;
  background-color: #395FC2;
  border-color: #395FC2;
  /** pa la card */
  margin-top: .5rem;
  float: right;
  width: 25%;
}

form .btn-submit.active, form .btn-submit:hover, form .btn-submit:focus, form .btn-submit:active {
  color: #fff;
  background-color: #284287;
  border-color: #284287;
}

form .wafoformautocomplete>.results {
  top: 68px;
  box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.1);
}
.navbar-panel {
  width: 100%;
  height: 50px;
  background-color: #395FC2 !important;
  /* shadow */
  box-shadow: 10px 10px 15px 0px rgba(0, 0, 0, 0.1);
}

.navbar-panel .navbar-brand {
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  padding: 2px 0px;
  font-weight: bold;
  color: #fff;
}

.navbar-panel .navbar-brand:hover {
  color: #313E61;
}

.navbar-panel .navbar-brand img {
  height: inherit;
  width: auto;
  margin-right: 10px;
}

.navbar-panel .btn-toggle {
  margin-right: 1rem;
}
.sidebar {
  min-width: 250px;
  max-width: 250px;
  height: 100vh;
  background-color: #313E61;
  color: #efefef;
  /* Transitions */
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.sidebar-links {
  list-style: none;
  padding: 1rem 0;
  border-top: 1px solid #efefef;
  border-bottom: 1px solid #efefef;
}

.sidebar-links li {
  cursor: pointer;
}

.sidebar-links li a.active {
  background: #395FC2;
  color: #efefef;
}

.sidebar-links a {
  display: block;
  width: 100%;
  padding: 10px 20px;
  color: #efefef;
  text-decoration: none;
  font-size: 1.1em;
  /* Transitions */
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.sidebar-links a:hover {
  color: #efefef;
  background: #395FC2;
}

.sidebar-links li.active a:hover {
  background: #395FC2;
  color: #efefef;
}

.sidebar-links a .svg-inline--fa {
  margin-right: 5px;
  width: 25px;
}

.sidebar-links a .fas {
    width: 25px;
}

.sidebar-links li .notification-badge {
  margin-left: .5rem;
  font-weight: bold;
  color: #000;
}

.btn-toggle-mobile {
  display: none;
}

.sidebar .header {
  padding: 1rem;
  padding-top: 1.25rem;
  text-align: center
}

.sidebar .header .svg-inline--fa {
  font-size: 6em;
}

.sidebar .header p {
  margin-bottom: 0;
}

.sidebar-links-extra {
  border: none;
  padding: 0;
}

/** ******************************************** */
/** Collapsed */
/** ******************************************** */

.sidebar.collapsed {
  max-width: 80px;
  min-width: 80px;
}

.sidebar.collapsed .header {
  display: none;
}

.sidebar.collapsed .sidebar-links a {
  padding: 10px;
  font-size: 0.8em;
  text-align: center;
  overflow: hidden;
  word-wrap: break-word;
}

.sidebar.collapsed .sidebar-links a .svg-inline--fa {
  margin: 0;
  width: 100%;
  font-size: 1.5em;
}

.sidebar.collapsed .sidebar-footer .texto-escondido {
  display: none;
}

.sidebar.collapsed .sidebar-links a span {
  display: none;
}

.sidebar.collapsed .sidebar-links li .notification-badge {
  display: block;
  margin: 0;
  font-size: 1em;
}

/** ******************************************** */
/** Movil */
/** ******************************************** */

@media (max-width: 768px) {
  .sidebar.collapsed {
    max-width: 0px;
    min-width: 0px;
  }
  .sidebar.collapsed .sidebar-links {
    display: none;
  }
}
.modal-monitor .backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 9999;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

.modal-monitor .card {
  max-width: 480px;
  box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.1);
}

.modal-monitor .card-header {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  color: #f5f5f5;
  border: none;
}

.modal-monitor .card-header h5 {
  margin-right: 1.5rem;
  font-weight: bold;
}

.modal-monitor .card-header .close {
  color: #fff;
  opacity: 0.9;
}

.modal-monitor .card-body.detalles .dato {
  margin-bottom: .25rem;
}

.modal-monitor .card-body.detalles .dato .badge {
  font-size: 0.8em;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
}

.modal-monitor .card-body .separador {
  background-color: #ccc;
  height: 1px;
  margin: .5rem 0;
}

.modal-monitor .card-body.detalles .dato .status {

}

.modal-monitor .card-body.detalles .dato .status .badge {
  width: auto;
  margin-right: .5rem;
}

.modal-monitor .card-body.detalles .dato .status .badge:last-child {
  margin-right: 0;
}

.modal-monitor .animate-in {
  /* Safari, Chrome and Opera > 12.1 */
  -webkit-animation: fadein .5s;
  /* Firefox < 16 */
  /* Internet Explorer */
  /* Opera < 12.1 */
  animation: fadein .5s;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Firefox < 16 */

/* Safari, Chrome and Opera > 12.1 */

@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Internet Explorer */

/* Opera < 12.1 */
.googleAutocomplete {
  position: relative;
}

.googleAutocomplete .results {
  background-color: #fff;
  border: 1px solid #d9d9d9;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
  position: absolute;
  z-index: 10000;
  top: 40px;
  width: 100%;
}

.googleAutocomplete .results ul {
  list-style: none;
  margin-bottom: 0;
  padding: 0;
}

.googleAutocomplete .results ul li {
  padding: 0 4px;
  line-height: 30px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  border-bottom: 1px solid #e6e6e6;
  cursor: pointer;
}

.googleAutocomplete .results ul li:last-child {
  border-bottom: none;
}

.googleAutocomplete .results ul li:hover, .googleAutocomplete .results ul li:focus {
  background-color: #eee;
}

.googleAutocomplete .results ul li .primary {
  font-size: 0.8em;
  margin-right: 5px;
}

.googleAutocomplete .results ul li .secondary {
  font-size: 0.75em;
  color: #777;
}

.googleAutocomplete .results ul li .svg-inline--fa {
  margin: 0 5px;
  color: #777;
}
.leaflet-container {
  height: 60vh;
  width: 100%;
}

.map.card {}

.map.card .card-body {
  padding: 1.25rem 0;
  padding-bottom: 0;
}

.map.card .card-footer p {
  margin: 0;
  font-size: 0.8em;
  color: #777;
}

.map.card .controls {
  -webkit-flex-direction: column;
          flex-direction: column;
  margin-bottom: 0;
}

.map.card .controls .line {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  width: 100%;
  padding: 0 1.25rem;
  margin-bottom: 1rem;
}

.map.card .controls .line .form-group {
  padding: 0;
}

.map.card .controls .line .btn, .map.card .controls .line .wafoformautocomplete, .map.card .controls .line .googleAutocomplete {
  margin-right: .5rem;
}

.map.card .controls .line .btn:last-child {
  margin-right: 0;
}

.map.card .controls .googleAutocomplete {
  -webkit-flex: 2 1;
          flex: 2 1;
}

.map.card .controls .wafoformautocomplete {
  -webkit-flex: 1 1;
          flex: 1 1;
}

.map.card .controls .wafoformautocomplete .results {
  z-index: 100000;
  top: 40px;
  box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.1);
}

.map.card .controls .form-group {
  margin-bottom: 0;
}

.customMapMarker {
  height: 65px !important;
  width: 45px !important;
}

.customMapMarker > div {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  -webkit-align-items: center;
          align-items: center;
}
.sl-confirmacion .backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 999999;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

.sl-confirmacion .card {
  width: 32em;
}

.sl-confirmacion .card .card-body {
  padding: 1.25em;
  padding-top: 2.5em;
  text-align: center;
}

.sl-confirmacion .card .card-body .svg-inline--fa {
  font-size: 7em;
  margin-bottom: 1.5rem;
  color: #ffc107;
}

.sl-confirmacion .title {
  font-weight: bold;
  text-align: center;
  font-size: 2em;
}

.sl-confirmacion .message {
  text-align: center;
  font-size: 1.2em;
  margin-bottom: 1.25rem;
}

.sl-confirmacion .buttons {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.sl-confirmacion .buttons .btn {
  margin: 0 .25rem;
  min-width: 25%;
}
.shy-header {
  font-weight: bold;
  margin-bottom: 0;
  color: #ccc;
  font-size: 1.1em;
}

.monitor .filtros .wafoformautocomplete .results {
  z-index: 100000;
  top: 40px;
  box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.1);
}

.monitor .filtros .botones {
  text-align: right;
}

.monitor .tabla-monitor .badge {
  width: 125px;
}

.monitor .mapa-monitor .card,
.monitor .tabla-monitor .card {
  border: 1px solid #395FC2;
}

.monitor .mapa-monitor .card>.card-header,
.monitor .tabla-monitor .card>.card-header {
  background-color: #395FC2;
  color: #efefef;
  font-weight: bold;
}
.modal-vehicle .backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 9999;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

.modal-vehicle .card {
  width: 40%;
  box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.1);
}

.modal-vehicle .card-header {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  color: #f5f5f5;
  background-color: #395FC2;
  border: none;
}

.modal-vehicle .card-header h5 {
  margin-right: 1.5rem;
  font-weight: bold;
}

.modal-vehicle .card-header .close {
  color: #fff;
  opacity: 0.9;
}

.modal-vehicle .card-body.detalles .dato {
  margin-bottom: .25rem;
}

.modal-vehicle .card-body.detalles .dato .badge {
  font-size: 0.8em;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
}

.modal-vehicle .card-body .separador {
  background-color: #ccc;
  height: 1px;
  margin: .5rem 0;
}

.modal-vehicle .card-body.detalles .dato .status {}

.modal-vehicle .card-body.detalles .dato .status .badge {
  width: auto;
  margin-right: .5rem;
}

.modal-vehicle .card-body.detalles .dato .status .badge:last-child {
  margin-right: 0;
}

.modal-vehicle .animate-in {
  /* Safari, Chrome and Opera > 12.1 */
  -webkit-animation: fadein .5s;
  /* Firefox < 16 */
  /* Internet Explorer */
  /* Opera < 12.1 */
  animation: fadein .5s;
}

@keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* Firefox < 16 */

/* Safari, Chrome and Opera > 12.1 */

@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* Internet Explorer */

/* Opera < 12.1 */
.leaflet-container {
  height: 60vh;
  width: 100%;
}

.map.card {}

.map.card .card-body {
  padding: 1.25rem 0;
  padding-bottom: 0;
}

.map.card .card-footer p {
  margin: 0;
  font-size: 0.8em;
  color: #777;
}

.map.card .controls {
  -webkit-flex-direction: column;
          flex-direction: column;
  margin-bottom: 0;
}

.map.card .controls .line {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  width: 100%;
  padding: 0 1.25rem;
  margin-bottom: 1rem;
}

.mapa-locations .controls .line label {
  font-weight: bold;
  color: #777;
  margin-bottom: 0;
}

.map.card .controls .line .form-group {
  padding: 0;
}

.mapa-locations .map.card .controls .line .wafoformautocomplete .results {
  top: 60px;
}

.map.card .controls .line .btn, .map.card .controls .line .wafo-input, .map.card .controls .line .sl-horariopicker {
  margin-right: .5rem;
}

.map.card .controls .line .btn:last-child {
  margin-right: 0;
}

.map.card .controls .wafoformautocomplete {
  -webkit-flex: 3 1 !important;
          flex: 3 1 !important;
}

.map.card .controls .wafo-input {
  -webkit-flex: 2 1;
          flex: 2 1
}

.map.card .controls .wafo-input, .map.card .controls .sl-horariopicker {
  -webkit-flex: 1 1;
          flex: 1 1;
}

.map.card .controls .wafoformautocomplete .results {
  z-index: 100000;
  top: 40px;
  box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.1);
}

.map.card .controls .form-group {
  margin-bottom: 0;
}
.historial .mapa-locations .card,
.historial .tabla-monitor .card,
.historial .tabla-historial .card,
.historial .tabla-historial .card {
  border: 1px solid #395fc2;
}

.historial .mapa-locations .card > .card-header,
.historial .tabla-monitor .card > .card-header,
.historial .tabla-historial .card > .card-header,
.historial .tabla-historial .card > .card-header {
  background-color: #395fc2;
  color: #efefef;
  font-weight: bold;
}

.historial .tabla-historial .card > .card-body .distance {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: flex-end;
          align-items: flex-end;
  margin-bottom: 1rem;
}

.historial .tabla-historial .card > .card-body .distance > h5 {
  margin-bottom: 0;
  margin-right: .5rem;
  font-weight: bold;
}
.alertas .card {
  border: 1px solid #395FC2;
}

.alertas .card .card-header {
  background-color: #395FC2;
  color: #efefef;
  font-weight: bold;
}

.alertas .tabla-alertas .badge-status {
  width: 120px;
}

.alertas .tabla-alertas .filtros .botones {
  text-align: right;
}

.alertas .tabla-alertas .filtros .wafoformautocomplete .results {
  top: 40px;
  box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.1);
}
.alertas-sl {
  position: absolute;
  z-index: 10000;
  top: 1.5rem;
  right: 1.5rem;
  margin-bottom: 0;
  padding: 0;
}

.alert-sl {
  width: 50vw;
  /* Shadow */
  box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.3);
}

.alert-sl.animate-in {
  /* Safari, Chrome and Opera > 12.1 */
  -webkit-animation: fadein .5s;
  /* Firefox < 16 */
  /* Internet Explorer */
  /* Opera < 12.1 */
  animation: fadein .5s;
}

@keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Firefox < 16 */

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Internet Explorer */

/* Opera < 12.1 */

.alert-sl.animate-out {
  /* Safari, Chrome and Opera > 12.1 */
  -webkit-animation: fadeout .5s;
  /* Firefox < 16 */
  /* Internet Explorer */
  /* Opera < 12.1 */
  animation: fadeout .5s;
}

@keyframes fadeout {
  from { opacity: 1; }
  to   { opacity: 0; }
}

/* Firefox < 16 */

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadeout {
  from { opacity: 1; }
  to   { opacity: 0; }
}

/* Internet Explorer */

/* Opera < 12.1 */
.loading-bar {
  position: absolute;
  top: 0;
  z-index: 1000;
  width: 100%;
}

.sl-loadingBar {
  width: 100%;
  position: relative;
  overflow: hidden;
}

.sl-loadingBar .innerBar {
  display: block;
  position: absolute;
  content: "";
  left: -200px;
  width: 200px;
  -webkit-animation: loading 2s linear infinite;
          animation: loading 2s linear infinite;
}

@-webkit-keyframes loading {
  from {
    left: -200px;
    width: 30%;
  }

  50% {
    width: 30%;
  }

  70% {
    width: 70%;
  }

  80% {
    left: 50%;
  }

  95% {
    left: 120%;
  }

  to {
    left: 100%;
  }
}

@keyframes loading {
  from {
    left: -200px;
    width: 30%;
  }

  50% {
    width: 30%;
  }

  70% {
    width: 70%;
  }

  80% {
    left: 50%;
  }

  95% {
    left: 120%;
  }

  to {
    left: 100%;
  }
}
/** ********************** */

/** Panel */

/** ********************** */

.panel-admin .panel-body {
  display: -webkit-flex;
  display: flex;
  height: calc(100vh - 50px);
  overflow: hidden;
  position: relative;
}

.panel-admin .panel-body .right {
  overflow-y: auto;
  -webkit-flex-basis: 100%;
          flex-basis: 100%;
  padding: 50px 0;
}

.panel-admin .panel-body .right h2 {
  font-weight: bold;
}

.panel-admin .panel-body .border-top-square {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border: 1px solid #395FC2;
  border-top: none;
}

/** ********************** */

/** Pills */

/** ********************** */

.sl-pills {
  border-top-left-radius: .25rem;
  border-top-right-radius: .25rem;
  background-color: #395FC2;
  padding: 0.5rem 1.25rem;
}

.sl-pills .nav-item {
  margin-right: 1rem;
}

.sl-pills .nav-item .btn {
  color: #efefef;
  padding: 0.25rem 1rem;
  border: none;
}

.sl-pills .nav-item .btn.active,
.sl-pills .nav-item .btn:hover {
  background-color: #56CCF2;
  color: #fff;
}

.sl-pills .nav-item .btn .svg-inline--fa {
  margin-right: .5rem;
}

/** ********************** */

/** Tabla */

/** ********************** */

.panel-admin .datatable-wrapper .btn {
  margin-right: .5rem;
}

.panel-admin .datatable-wrapper .btn:last-child {
  margin-right: 0;
}

.panel-admin .datatable-wrapper .badge {
  padding: .25rem 1.5rem;
}

.panel-admin .filtros .wafoformautocomplete .results {
  top: 40px;
  box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.1);
}

.panel-admin .filtros .botones {
  text-align: right;
}

/** ********************** */

/** Detalles */

/** ********************** */

.detalles .header {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  margin-bottom: 1rem;
}

.detalles .header h3 {
  margin-bottom: 0;
  font-weight: bold;
}

.detalles .header .badge {
  padding: .25rem 1.5rem;
}

.detalles .dato {
  margin-bottom: 1rem;
}

.detalles .dato p {
  margin-bottom: 0;
}

.detalles .dato .label {
  font-size: 0.9em;
  color: #777;
}

.detalles .dato .value {
  font-weight: bold;
}

.detalles .dato .badge {
  font-size: 0.9em;
  padding: .25rem 1.5rem;
}

.detalles .header h3,
.detalles .header .badge {
  margin-right: 1rem;
}

/** Tables **/
.controls {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.controls label {
  font-size: .8em;
  margin-right: 0.5rem;
}

.pagination-wrapper {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -webkit-align-items: flex-start;
  align-items: flex-start;
}

.pagination-wrapper .description {
  font-size: .8em;
  margin: 0;
}

.pagination-wrapper nav {
  color: blue;
}

.pagination-wrapper li {
  cursor: pointer;
}
.modal-vehicle .backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 9999;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

.modal-vehicle .card {
  width: 40%;
  box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.1);
}

.modal-vehicle .card-header {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  color: #f5f5f5;
  background-color: #395FC2;
  border: none;
}

.modal-vehicle .card-header h5 {
  margin-right: 1.5rem;
  font-weight: bold;
}

.modal-vehicle .card-header .close {
  color: #fff;
  opacity: 0.9;
}

.modal-vehicle .card-body.detalles .dato {
  margin-bottom: .25rem;
}

.modal-vehicle .card-body.detalles .dato .badge {
  font-size: 0.8em;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
}

.modal-vehicle .card-body .separador {
  background-color: #ccc;
  height: 1px;
  margin: .5rem 0;
}

.modal-vehicle .card-body.detalles .dato .status {}

.modal-vehicle .card-body.detalles .dato .status .badge {
  width: auto;
  margin-right: .5rem;
}

.modal-vehicle .card-body.detalles .dato .status .badge:last-child {
  margin-right: 0;
}

.modal-vehicle .animate-in {
  /* Safari, Chrome and Opera > 12.1 */
  -webkit-animation: fadein .5s;
  /* Firefox < 16 */
  /* Internet Explorer */
  /* Opera < 12.1 */
  animation: fadein .5s;
}

@keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* Firefox < 16 */

/* Safari, Chrome and Opera > 12.1 */

@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* Internet Explorer */

/* Opera < 12.1 */
.login .container-fluid {
  height: 100vh;
  background-color: #fff;
  background-image: url(/static/media/topography.977e3fd0.png);
}

.login .container-fluid>.row {
  height: 100%;
}

.login img {
  width: 50%;
  display: block;
  margin: auto;
  margin-bottom: .5rem;
}

.login .btn-submit {
  width: 100%;
  margin-top: .5rem;
}

.login form {
  margin-bottom: .5rem;
}

.login .motd {
  margin-bottom: 0;
  text-align: center;
  color: #777;
  font-size: 0.9em;
  margin-top: 1.5rem;
}

.login .btn-link {
  padding-left: 0;
}
