.login .container-fluid {
  height: 100vh;
  background-color: #fff;
  background-image: url('../../assets/images/topography.png');
}

.login .container-fluid>.row {
  height: 100%;
}

.login img {
  width: 50%;
  display: block;
  margin: auto;
  margin-bottom: .5rem;
}

.login .btn-submit {
  width: 100%;
  margin-top: .5rem;
}

.login form {
  margin-bottom: .5rem;
}

.login .motd {
  margin-bottom: 0;
  text-align: center;
  color: #777;
  font-size: 0.9em;
  margin-top: 1.5rem;
}

.login .btn-link {
  padding-left: 0;
}