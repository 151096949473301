/** ********************** */

/** Panel */

/** ********************** */

.panel-admin .panel-body {
  display: flex;
  height: calc(100vh - 50px);
  overflow: hidden;
  position: relative;
}

.panel-admin .panel-body .right {
  overflow-y: auto;
  flex-basis: 100%;
  padding: 50px 0;
}

.panel-admin .panel-body .right h2 {
  font-weight: bold;
}

.panel-admin .panel-body .border-top-square {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border: 1px solid #395FC2;
  border-top: none;
}

/** ********************** */

/** Pills */

/** ********************** */

.sl-pills {
  border-top-left-radius: .25rem;
  border-top-right-radius: .25rem;
  background-color: #395FC2;
  padding: 0.5rem 1.25rem;
}

.sl-pills .nav-item {
  margin-right: 1rem;
}

.sl-pills .nav-item .btn {
  color: #efefef;
  padding: 0.25rem 1rem;
  border: none;
}

.sl-pills .nav-item .btn.active,
.sl-pills .nav-item .btn:hover {
  background-color: #56CCF2;
  color: #fff;
}

.sl-pills .nav-item .btn .svg-inline--fa {
  margin-right: .5rem;
}

/** ********************** */

/** Tabla */

/** ********************** */

.panel-admin .datatable-wrapper .btn {
  margin-right: .5rem;
}

.panel-admin .datatable-wrapper .btn:last-child {
  margin-right: 0;
}

.panel-admin .datatable-wrapper .badge {
  padding: .25rem 1.5rem;
}

.panel-admin .filtros .wafoformautocomplete .results {
  top: 40px;
  -webkit-box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.1);
}

.panel-admin .filtros .botones {
  text-align: right;
}

/** ********************** */

/** Detalles */

/** ********************** */

.detalles .header {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.detalles .header h3 {
  margin-bottom: 0;
  font-weight: bold;
}

.detalles .header .badge {
  padding: .25rem 1.5rem;
}

.detalles .dato {
  margin-bottom: 1rem;
}

.detalles .dato p {
  margin-bottom: 0;
}

.detalles .dato .label {
  font-size: 0.9em;
  color: #777;
}

.detalles .dato .value {
  font-weight: bold;
}

.detalles .dato .badge {
  font-size: 0.9em;
  padding: .25rem 1.5rem;
}

.detalles .header h3,
.detalles .header .badge {
  margin-right: 1rem;
}

/** Tables **/
.controls {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.controls label {
  font-size: .8em;
  margin-right: 0.5rem;
}

.pagination-wrapper {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -webkit-align-items: flex-start;
  align-items: flex-start;
}

.pagination-wrapper .description {
  font-size: .8em;
  margin: 0;
}

.pagination-wrapper nav {
  color: blue;
}

.pagination-wrapper li {
  cursor: pointer;
}