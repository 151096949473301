.navbar-panel {
  width: 100%;
  height: 50px;
  background-color: #395FC2 !important;
  /* shadow */
  -webkit-box-shadow: 10px 10px 15px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 10px 10px 15px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 10px 10px 15px 0px rgba(0, 0, 0, 0.1);
}

.navbar-panel .navbar-brand {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 2px 0px;
  font-weight: bold;
  color: #fff;
}

.navbar-panel .navbar-brand:hover {
  color: #313E61;
}

.navbar-panel .navbar-brand img {
  height: inherit;
  width: auto;
  margin-right: 10px;
}

.navbar-panel .btn-toggle {
  margin-right: 1rem;
}