.sl-confirmacion .backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 999999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sl-confirmacion .card {
  width: 32em;
}

.sl-confirmacion .card .card-body {
  padding: 1.25em;
  padding-top: 2.5em;
  text-align: center;
}

.sl-confirmacion .card .card-body .svg-inline--fa {
  font-size: 7em;
  margin-bottom: 1.5rem;
  color: #ffc107;
}

.sl-confirmacion .title {
  font-weight: bold;
  text-align: center;
  font-size: 2em;
}

.sl-confirmacion .message {
  text-align: center;
  font-size: 1.2em;
  margin-bottom: 1.25rem;
}

.sl-confirmacion .buttons {
  display: flex;
  justify-content: center;
  align-items: center;
}

.sl-confirmacion .buttons .btn {
  margin: 0 .25rem;
  min-width: 25%;
}