.alertas .card {
  border: 1px solid #395FC2;
}

.alertas .card .card-header {
  background-color: #395FC2;
  color: #efefef;
  font-weight: bold;
}

.alertas .tabla-alertas .badge-status {
  width: 120px;
}

.alertas .tabla-alertas .filtros .botones {
  text-align: right;
}

.alertas .tabla-alertas .filtros .wafoformautocomplete .results {
  top: 40px;
  -webkit-box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.1);
}