.googleAutocomplete {
  position: relative;
}

.googleAutocomplete .results {
  background-color: #fff;
  border: 1px solid #d9d9d9;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
  position: absolute;
  z-index: 10000;
  top: 40px;
  width: 100%;
}

.googleAutocomplete .results ul {
  list-style: none;
  margin-bottom: 0;
  padding: 0;
}

.googleAutocomplete .results ul li {
  padding: 0 4px;
  line-height: 30px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  border-bottom: 1px solid #e6e6e6;
  cursor: pointer;
}

.googleAutocomplete .results ul li:last-child {
  border-bottom: none;
}

.googleAutocomplete .results ul li:hover, .googleAutocomplete .results ul li:focus {
  background-color: #eee;
}

.googleAutocomplete .results ul li .primary {
  font-size: 0.8em;
  margin-right: 5px;
}

.googleAutocomplete .results ul li .secondary {
  font-size: 0.75em;
  color: #777;
}

.googleAutocomplete .results ul li .svg-inline--fa {
  margin: 0 5px;
  color: #777;
}